








import Vue from "vue"
import Component from 'vue-class-component'
import fireClient from '../service/firebase-client'
import firebase from "firebase/app"
import 'firebaseui/dist/firebaseui.css'

@Component
export default class LoginDialog extends Vue {
  msg: string = ''

  mounted() {
    let self = this
    fireClient.ui().start('#firebaseui-auth-container', {
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {          
          console.log('sign in success ', authResult, redirectUrl)
          // debugger
          let provider = authResult.additionalUserInfo.providerId
          // Facebook could have an email on profile which has not been verified,
          // and we are ok with that, email could still be valid.
          if (provider == "facebook.com" ||
              authResult.user.emailVerified) {
            self.$emit("logged", authResult.user)
          }
          else
            self.msg = "Please verify your email."
          return false;
        },
      },
      signInFlow: "popup",
      // signInSuccessUrl: '/app',
      signInOptions: [        
        { provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: { prompt: 'select_account' },
        }, 
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        }
      ],
      // Other config options...
    })
  }
}

