
export class Project {

  public name : string

  constructor(readonly id: string, readonly serviceLocation: string, name : string) {
    this.name = name
  }

  static fromJson(data: any): Project {
    return new Project(data.id, 
                       data.serviceLocation, 
                       data.name)
  }
}