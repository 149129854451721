import { GranthikaError } from '@/help'
import iso_fetch from 'isomorphic-fetch'

export class ServerResponse { 
  ok: boolean = true
  error?: string
  errorType?: string 
}

export async function get<ResponseType extends ServerResponse>
        (path : string, authorization : string) : Promise<ServerResponse> {
  let response = await (await iso_fetch("/console/rest" + path, {
    headers: {
      'Accept': 'application/json',
      'Authorization' : authorization
    },
  })).json()
  if (!response.ok) {
    console.error('While getting on ' + path, 'response is', response)
    throw new Error(response.error)
  }
  else
    return response  
}

export async function post<ResponseType extends ServerResponse>
        (path : string, payload : object, authorization : string): Promise<ResponseType> {
  try {
    let response: { ok: boolean, error: string } = await (await iso_fetch("/console/rest" + path, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization' : authorization
      },
      method: 'POST',
      body: JSON.stringify(payload)
    })).json()
    console.log('response ', response)
    if (!response.ok) {
      console.error('While posting on ' + path, payload, 'response is', response)
      throw new GranthikaError(response)
    }
    else
      return response as ResponseType
  }
  catch (err) {
    console.error('While posting to ' + path, payload, err)
    throw err
  }
}