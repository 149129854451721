import { Project } from '@/model/project'
import { get, post } from './api'

export default class ProjectService {

  private path : string = '/project'
  private userToken : string

  constructor(userToken : string) {
    this.userToken = userToken
  }

  public async all() : Promise<Project[]> {
    let res: any = (await get(this.path + '/all', this.userToken))
    return res.projects.map(Project.fromJson)
  }
 
  public async create(name: string): Promise<Project> {
    let result = (await post(this.path + "/create", { name }, this.userToken))
    return Project.fromJson(result)
  }
}