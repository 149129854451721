






















import * as Cookies from 'es-cookie'
import { Component, Vue } from 'vue-property-decorator'
import login from './components/login.vue'
import User from './model/user'
import UserService from './service/user-service'
import ProjectService from './service/project-service'
import { Project } from './model/project'
import fireClient from "./service/firebase-client"
import { GranthikaError } from './help'

@Component({
  components: {
    login
  },
})
export default class App extends Vue {
  view: string = ""  
  userData  : User | null = null
  projects  : Array<Project> = []
  dialog = false
  error: GranthikaError = GranthikaError.none
  fatalErrorIntro = `
        It seems the Granthka is unable to open your project at the moment. 
        Most likely, this is due to a temporary maintenance on our servers. 
        Rest assured, your data is safe. To help is identify the root cause, 
        please contact Granthika at support@granthika.co 
        and copy and paste the information below in your email. 
  `

  private handleError(err: any) {
    this.error = GranthikaError.make(err)
    this.error.nextAction = 'initFireAuth'
    this.error.dismissLabel = "Try Logging Again"
    if (this.error.type == 'account-deleted') {
      this.error.nextAction = 'bailOut'
      this.error.dismissLabel = "Ok"
    }
    this.view = 'error'
  }

  bailOut() {
    this.userData = null
    this.view = "login"
  }

  initFireAuth() {
    fireClient.auth().onAuthStateChanged(async (user : any) => {
      console.log('user', user)
      if (user && user.emailVerified) {
        this.onLogged(user)
      }
      else {
        //logout
        this.userData = null
        this.view = "login"
      }
    })    
  }

  mounted() {
    let self = this
    if (Cookies.get("granthika-signout-event") == "true") {
      let auth = fireClient.auth()
      auth.signOut().then(() => {
        self.view = "login"
        self.userData = null
        Cookies.remove("granthika-signout-event")
      })
    }
    else if (Cookies.get("granthika-stopredirect")) {
      this.view = "error"
      let errorMessage = Cookies.get("granthika-failure") || "Unknown error."
      let grantha = Cookies.get("grantha")
      let location = Cookies.get("grantha-service-location")
      let port = Cookies.get("grantha-service-port")
      if (grantha) {
        errorMessage += "; project: " + grantha
      }
      if (location) {
        errorMessage += "; location: " + location 
        if (port) 
          errorMessage += ":" + port
      }
      this.error = GranthikaError.make(errorMessage)
    }
    else 
      this.initFireAuth()
  }

  clearError() {
    Cookies.remove("granthika-failure")
    Cookies.remove("granthika-stopredirect")
    let continuation = this.error.nextAction ? this[this.error.nextAction] : null
    this.error = GranthikaError.none
    if (continuation)
      continuation()
  }

  openView(view: string) {
    console.log('goto view', view)
    this.view = view
  }
  
  get showError() { return this.view == "error" }

  async gotoProject(token: string, project: Project) {
    Cookies.set("grauth", token) 
    let locationParts = project.serviceLocation.split(":")
    Cookies.set("grantha", project.id) 
    Cookies.set('grantha-service-location', locationParts[0])
    Cookies.set('grantha-service-port', locationParts[1])
    window.top.location.href =  "/app"
  }

  async onLogged(user: any) {
      // the id token will be used by the BE to securely identify the user
      let idToken = await fireClient.auth().currentUser!.getIdToken(true)
      try {
        this.userData = await new UserService(idToken).setup(
          {token : idToken, email : user.email, 
          name : user.displayName, hghandle: '' })
        Cookies.set("gruh", this.userData.hghandle)
      }
      catch (error) {
        console.log('Failed to setup user ', user, error)
        this.handleError(error)
        return
      }
      let projectService = new ProjectService(idToken)
      this.projects = (await projectService.all()) as Array<Project>
      if (this.projects.length == 0) {
        let project = await projectService.create("untitled")
        this.projects.push(project)
        this.gotoProject(idToken, project)
      }
      else if (Cookies.get("grantha")) {
        let projectId = Cookies.get("grantha")
        let project: Project|undefined = this.projects.find((p: Project) => p.id == projectId)
        if (project)
          this.gotoProject(idToken, project)
        else {
          // fallback to first project
          this.gotoProject(idToken, this.projects[0])
        }
      }
      else
        // Until we have a proper interface to display a list of projects
        // to do user, we just go to the first one that came up, and the
        // user could open from there easily.
        this.gotoProject(idToken, this.projects[0])
  }
}
